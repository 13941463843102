import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from './logo';
import './navbar.css';

const Navbar = () => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navbarRef = useRef(null);

    // Handle dropdown toggle
    const handleDropdownToggle = (dropdown) => {
        setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    };

    const handleLinkClick = () => {
        setOpenDropdown(null);
        setMobileMenuOpen(false); // Close the mobile menu on link click
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setOpenDropdown(null);
                setMobileMenuOpen(false); // Close the menu if clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="top-navbar">
                <div className="container">
                    <div className="email-phone">
                        <p><span role="img" aria-label="phone">📞</span> +971 527066365</p>
                        <span>md@arjunaaero.com</span>
                    </div>
                </div>
            </div>

            <nav className="navbar" ref={navbarRef}>
                <Link to="/" onClick={handleLinkClick}><Logo /></Link>

                {/* Hamburger icon */}
                <div className="hamburger" onClick={toggleMobileMenu}>
                    <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
                    <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
                    <div className={`bar ${isMobileMenuOpen ? 'open' : ''}`}></div>
                </div>

                <ul className={`nav-links ${isMobileMenuOpen ? 'show' : ''}`}>
                    {/* About Us Dropdown */}
                    <li className={`dropdown ${openDropdown === 'aboutUs' ? 'active' : ''}`}>
                        <button
                            type="button"
                            onClick={() => handleDropdownToggle('aboutUs')}
                            className="dropdown-link"
                        >
                            About Us
                        </button>
                        <ul className={`dropdown-menu ${openDropdown === 'aboutUs' ? 'show' : ''}`}>
                            <li><Link to="/about-us#our-company" onClick={handleLinkClick}>Our Company</Link></li>
                            <li><Link to="/about-us#vision-mission" onClick={handleLinkClick}>Mission & Vision</Link></li>
                            <li><Link to="/about-us#management" onClick={handleLinkClick}>Management</Link></li>
                        </ul>
                    </li>

                    {/* What We Do Dropdown */}
                    <li className={`dropdown ${openDropdown === 'whatWeDo' ? 'active' : ''}`}>
                        <button
                            type="button"
                            onClick={() => handleDropdownToggle('whatWeDo')}
                            className="dropdown-link"
                        >
                            What We Do
                        </button>
                        <ul className={`dropdown-menu ${openDropdown === 'whatWeDo' ? 'show' : ''}`}>
                            <li><Link to="/what-we-do?topic=Commercial-Aircraft-Support" onClick={handleLinkClick}>Commercial Aircraft Support</Link></li>
                            <li><Link to="/what-we-do?topic=General-Aircraft-Support" onClick={handleLinkClick}>General Aircraft Support</Link></li>
                            <li><Link to="/what-we-do?topic=Repair-and-Maintenance-Services" onClick={handleLinkClick}>Repair and Maintenance Services</Link></li>
                            <li><Link to="/what-we-do?topic=Aviation-Logistics-Solutions" onClick={handleLinkClick}>Aviation Logistics Solutions</Link></li>
                            <li><Link to="/what-we-do?topic=Terms-Conditions" onClick={handleLinkClick}>Terms & Conditions</Link></li>
                        </ul>
                    </li>

                    {/* Additional links (commented out for now) */}
                    {/* <li><Link to="/shop" onClick={handleLinkClick}>Shop</Link></li>
                    <li><Link to="/rfq" onClick={handleLinkClick}>RFQ</Link></li>
                    <li><Link to="/partssearch" onClick={handleLinkClick}>Parts Search</Link></li>
                    <li><Link to="/careers" onClick={handleLinkClick}>Careers</Link></li>
                    <li><Link to="/contact" onClick={handleLinkClick}>Contact</Link></li> */}
                </ul>
            </nav>
        </>
    );
};

export default Navbar;
