/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./homepage.css";
import Video from "../../assets/videos/video.mp4";

import Productimg from "../../assets/images/pexels-apasaric-929606.jpg";
import Aircraftnimg from "../../assets/images/parts.jpg";
import Repairsimg from "../../assets/images/istockphoto-1898273096-612x612.webp";
import Solutionimg from "../../assets/images/plane-is-runway-with-words-private-jet-front_188544-8032.png";
import { AutoComplete, Carousel, Input } from "antd";
import API from "../../baseURL.config.json";
import axios from "axios";
import image1 from "../../assets/images/pexels-apasaric-929606.jpg";
import image2 from "../../assets/images/parts.jpg";
import image3 from "../../assets/images/download.jpeg";
import image4 from "../../assets/images/plane-is-runway-with-words-private-jet-front_188544-8032.png";

const HomePage = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API.devURL}/api/getExcel`)
      .then((res) => {
        console.log("Response Data", res.data);
        const a = res.data[0].data.map((x, i) => {
          return {
            label: x[0] + " - " + x[1],
            value: x[0],
          };
        });
        console.log("Excel Data", a);
        setData(a);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, []);

  const handleSearch = (searchText) => {
    const filteredOptions = data.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  const handleSelect = (value) => {
    const selectedOption = data.find((item) => item.value === value);
    navigate("/shop", {
      state: { label: selectedOption.label, value: selectedOption.value },
    });
    localStorage.setItem("pot", selectedOption.value);
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="home-page">
      <Carousel arrows infinite={false} autoplay>
        <div>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              backgroundImage: `url(${image1})`,
              backgroundSize: "cover",
            }}
          ></h3>
        </div>
        <div>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              backgroundImage: `url(${image2})`,
              backgroundSize: "cover",
            }}
          ></h3>
        </div>
        <div>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              backgroundImage: `url(${image3})`,
              backgroundSize: "cover",
            }}
          ></h3>
        </div>
        <div>
          <h3
            style={{
              height: "100vh",
              color: "#fff",
              lineHeight: "160px",
              textAlign: "center",
              background: "#364d79",
              backgroundImage: `url(${image4})`,
              backgroundSize: "cover",
            }}
          ></h3>
        </div>
      </Carousel>

      <div className="video-container">
        {/* <video autoPlay loop muted className="background-video">
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}

        <center>
          <h1
            style={{
              position: "absolute",
              zIndex: "10",
              top: "35%",
              right: "35%",
              color: "white",
            }}
          >
            Explore our parts collection
          </h1>
        </center>
        <AutoComplete
          disabled
          options={options}
          style={{
            position: "absolute",
            zIndex: "10",
            top: "40%",
            right: "30%",
            width: "40%",
            paddingTop: "1em",
            paddingBottom: "1em",
            color: "black",
          }}
          onSearch={handleSearch}
          onSelect={handleSelect}
          placeholder="Search Parts Here........."
        >
          <Input disabled />
        </AutoComplete>
      </div>

      <div className="what-we-do">
        <h2>What We Do</h2>
        <div className="card-container">
          <Link
            to="/what-we-do?topic=General-Aircraft-Support"
            className="card"
          >
            <img src={Productimg} alt="Product Support" />
            <div className="card-title">General Aircraft Support</div>
          </Link>

          <Link
            to="/what-we-do?topic=Commercial-Aircraft-Support"
            className="card"
          >
            <img src={Aircraftnimg} alt="Vision & Mission" />
            <div className="card-title">Commercial Aircraft Support</div>
          </Link>

          <Link
            to="/what-we-do?topic=Repair-and-Maintenance-Services"
            className="card"
          >
            <img src={Repairsimg} alt="Services" />
            <div className="card-title">Repairs & Maintenance Services</div>
          </Link>

          <Link
            to="/what-we-do?topic=Aviation-Logistics-Solutions"
            className="card"
          >
            <img src={Solutionimg} alt="Solutions" />
            <div className="card-title">Aviation Logistics Solutions </div>
          </Link>
        </div>
      </div>

      <div className="about-us-section">
        <div className="about-content">
          <h2>About Us</h2>
          <p>
            Arjuna Aerospace is swiftly emerging as a premier distributor of
            aircraft spare parts, lubricants, ground support equipment, and more
            across Asia, Africa, and the Middle East. Our growth is attributed
            to the collective expertise of our team, which delivers innovative
            and value-driven solutions. Over time, Arjuna Aerospace has
            cultivated strategic partnerships with leading global companies and
            professionals, enabling us to offer round-the-clock (24/7) services
            tailored to your aviation needs from our offices in UAE (Sharjah).
          </p>
          <Link to="/about-us#our-company" className="know-more-button">
            Know More
          </Link>
        </div>
        <div className="about-sidebar">
          <div className="accordion">
            <div className="accordion-item">
              <h3
                className="accordion-title"
                onClick={() => toggleAccordion(0)}
              >
                Our Company
              </h3>
              <div
                className={`accordion-content ${
                  activeIndex === 0 ? "active" : ""
                }`}
              >
                <p>
                  Arjuna Aerospace is swiftly emerging as a premier distributor
                  of aircraft spare parts, lubricants, ground support equipment,
                  and more across Asia, Africa, and the Middle East.
                  <a href="/about-us#our-company" className="read-more-link">
                    Read More...
                  </a>
                </p>
              </div>
              <h3
                className="accordion-title"
                onClick={() => toggleAccordion(1)}
              >
                Vision & Mission
              </h3>
              <div
                className={`accordion-content ${
                  activeIndex === 1 ? "active" : ""
                }`}
              >
                <p>
                  Enhancing Customer delight by providing Realistic and
                  Efficient Aviation Solutions.
                  <a href="/about-us#vision-mission" className="read-more-link">
                    Read More...
                  </a>
                </p>
              </div>
              <h3
                className="accordion-title"
                onClick={() => toggleAccordion(2)}
              >
                Management
              </h3>
              <div
                className={`accordion-content ${
                  activeIndex === 2 ? "active" : ""
                }`}
              >
                <p>
                  Surpassing customer expectations through transparency,
                  accuracy, efficiency, and timeliness.
                  <a href="/about-us#management" className="read-more-link">
                    Read More...
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
