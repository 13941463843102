import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./component/navbar";
import Footer from "./component/footer";
import HomePage from "./pages/Homepage/homepage";
import AboutUs from "./pages/Aboutus/aboutus";
import WhatWeDo from "./pages/Whatwedo/whatwedo";
import Shop from "./pages/Shop/shop";
import RFQ from "./pages/RFQ/rfq";
import PartsSearch from "./pages/Partssearch/partssearch";
import Careers from "./pages/Careers/careers";
import Contact from "./pages/Contact/contact";
import Login from "./pages/Login/login";
import AdminTable from "./admin/AdminTable";
import "./App.css";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/what-we-do" element={<WhatWeDo />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/RFQ" element={<RFQ />} />
          <Route path="/partssearch" element={<PartsSearch />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/adminTable" element={<AdminTable />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
